import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  RouterLink,
} from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import {
  NgxSkeletonLoaderComponent,
  NgxSkeletonLoaderModule,
} from 'ngx-skeleton-loader';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';
import { ViewHeaderComponent } from 'src/app/components/_layout/view-header/view-header.component';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { DataTableService } from 'src/app/components/data-table/data-table.service';
import { ButtonComponent } from 'src/app/components/form/button/button.component';
import { HttpMethod } from 'src/app/enum/http-method';
import { LocationsService } from 'src/app/services/locations.service';

export const locationsListResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(LocationsService).getLocationsList();
};
@Component({
  selector: 'app-locations-view',
  templateUrl: './locations.view.html',
  styleUrls: ['./locations.view.scss'],
  standalone: true,
  imports: [
    ViewHeaderComponent,
    ViewContentComponent,
    MatRipple,
    SvgIconComponent,
    RouterLink,
    ButtonComponent,
    MatTableModule,
    NgxSkeletonLoaderModule,
  ],
  host: {
    id: 'view',
  },
})
export class LocationsViewComponent
  extends DataTableComponent<any, any>
  implements OnInit
{
  // public locations = [];

  public override scrollContainerId: string = 'view';

  public override dataUrl = 'locations';
  public override method = HttpMethod.Get;

  public columns = ['product_grid_item'];

  constructor(
    dataService: DataTableService<any, any>,
    cd: ChangeDetectorRef,
    private _route: ActivatedRoute,
  ) {
    super(dataService, cd);
    const { locations } = _route.snapshot.data || {};
    this.initialData = locations;
    // this.locations = locations?.items ?? [];
  }

  protected override getData() {
    return null;
  }
}
