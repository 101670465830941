<view-header label="menu.navigator.locations">
  <div class="flex w-full justify-end">
    <button
      appButton
      label="action.add_new"
      routerLink="add"
      icon="monochromePlus"
      color="transparent"
    ></button>
  </div>
</view-header>

<view-content>
  <mat-table
    class="grid-table !grid !grid-cols-1 gap-6x md:!grid-cols-1 lg:!grid-cols-1"
    [dataSource]="dataSource"
    [@.disabled]="true"
  >
    <!-- GRID VIEW COL - Use as a single column -->
    <ng-container matColumnDef="product_grid_item">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let location">
        <div
          class="flex w-full cursor-pointer items-center gap-5x border border-grey-20 pr-8x"
          matRipple
          [routerLink]="location.id"
        >
          <div class="flex items-center gap-6x">
            <!-- LOCATION IMAGE -->
            <div
              class="flex size-25x min-w-25x items-center justify-center bg-grey-10"
            >
              @if(location.thumbnail) {
              <img
                class="h-full w-full object-fill"
                [src]="'data:' + location.thumbnailContentType + ';base64,' + location.thumbnailSmall"
                alt="Location thumbnail"
              />
              } @else {
              <svg-icon name="monochromeAddress"></svg-icon>
              }
            </div>
            <!-- LOCATION INFO -->
            <div class="flex flex-col gap-1x">
              <div
                class="line-clamp-1 font-display text-sm font-medium text-grey-100"
              >
                {{ location.name }}
              </div>
              <div class="line-clamp-1 font-text text-sm text-grey-70">
                {{ location.address }}
              </div>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>

  @if (dataSource.isLoading) {
  <div class="w-full py-6x">
    <ngx-skeleton-loader
      class="size-16"
      [count]="3"
      [theme]="{
          height: '6rem',
          opacity: 0.5,
          background: 'var(--grey-alt)',
        }"
    ></ngx-skeleton-loader>
  </div>
  }
</view-content>
